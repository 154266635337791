<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { watch, ref, onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia'
import { useStorePersist } from './composables'
import { useAppStore } from './stores/app'

import HomeView from './views/HomeView.vue'
import ProgressView from './views/ProgressView.vue'
import ParseSuccess from './views/ParseSuccess.vue'

useStorePersist()
const appStore = useAppStore()
const { process } = storeToRefs(appStore)
const step = ref(1)
const timeoutId = ref<any>(null)

const cancelParsing = (id: string) => {
  appStore.cancelParsing(id)
}

const restart = () => {
  appStore.restart()
}

watch(
  process,
  (newValue) => {
    if (!newValue) {
      step.value = 1
      return
    }

    if (newValue && newValue?.status?.status !== 'completed') {
      step.value = 2
      return
    }

    if (newValue?.status?.status === 'completed') {
      timeoutId.value = setTimeout(() => {
        step.value = 3
      }, 2000)
    }
  },
  {
    immediate: true
  }
)

onBeforeMount(() => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value)
  }
})

defineExpose({
  cancelParsing,
  restart
})
</script>

<template>
  <div
    class="flex flex-col items-center rounded-[16px] w-full bg-white"
    :class="[step === 3 ? 'h-full' : 'h-auto']"
  >
    <HomeView class="w-full max-w-2xl" v-if="step === 1" />
    <ProgressView class="w-full max-w-2xl" v-if="step === 2" />
    <ParseSuccess class="w-full" v-if="step === 3" />
  </div>
</template>

<style>
@import url('./assets/main.css');
</style>
