import { defineCustomElementWrapped } from './utils/defineElement'
import App from './App.ce.vue'
import ConfirmModal from './components/ConfirmModal.ce.vue'
import SpinningLoader from './components/common/SpinningLoader.ce.vue'
import GuidePrompt from './components/GuidePrompt.ce.vue'

import { initialiseInterceptors } from './utils/axiosUtil'

import PrimeVue from 'primevue/config'

//@ts-ignore
import Lara from './presets/lara'

initialiseInterceptors()

const appComponent = defineCustomElementWrapped(App, {
  plugins: [
    (app: any) => {
      app.use(PrimeVue, {
        unstyled: true,
        pt: Lara
      })
    }
  ]
})

const appLoader = defineCustomElementWrapped(SpinningLoader, {
  plugins: []
})

const appConfirmModal = defineCustomElementWrapped(ConfirmModal, {
  plugins: [
    (app: any) => {
      app.use(PrimeVue, {
        unstyled: true,
        pt: Lara
      })
    }
  ]
})

const appGuidePrompt = defineCustomElementWrapped(GuidePrompt, {
  plugins: [
    (app: any) => {
      app.use(PrimeVue, {
        unstyled: true,
        pt: Lara
      })
    }
  ]
})

//custom elements
customElements.define('app-component', appComponent)
customElements.define('app-confirm-modal', appConfirmModal)
customElements.define('app-loader', appLoader)
customElements.define('app-guide-prompt', appGuidePrompt)
