import { defineCustomElement, createApp, h, getCurrentInstance } from 'vue'

export const defineCustomElementWrapped = (
  component: any,
  { plugins = [] }: { plugins?: any[] } = {}
) => {
  return defineCustomElement({
    ...component,
    setup(props: any, options: any) {
      const app = createApp(component)

      plugins.forEach((plugin) => {
        if (typeof plugin === 'function') {
          plugin(app)
        } else {
          app.use(plugin)
        }
      })

      const inst = getCurrentInstance()

      if (inst) {
        Object.assign(inst.appContext, app._context)
        Object.assign(inst?.appContext.provides || {}, app._context.provides)
      }

      const setup = component.setup

      if (typeof setup === 'function') {
        return setup(props, options)
      }
    }

    //https://stackoverflow.com/questions/69808113/how-to-use-vue-router-and-vuex-inside-custom-element-from-root
  })
}
