<script setup lang="ts">
import { useAppStore } from '@/stores/app'

import FileUploader from '@/components/FileUploader.ce.vue'
import Badge from '@/components/common/Badge.vue'

const appStore = useAppStore()

const goToManual = () => {
  const location = window.location.origin
  window.location.href = `${location}/create-deal`
}

const afterUploadHook = async (data: any) => {
  appStore.document = data
  await appStore.getParsingStatus(data.id)
}
</script>

<template>
  <main
    class="flex flex-col items-center justify-center w-full p-[16px] pt-[50px] h-auto md:h-[500px]"
  >
    <div class="flex flex-col md:flex-row items-center gap-2">
      <p class="text-2xl font-extrabold text-center">Auto-extract details with AI</p>
      <Badge
        label="Beta"
        variant="accent"
        class="!bg-fl-yellow-500 border-0 text-white font-medium"
      />
    </div>
    <p class="font-normal text-sm leading-5 mt-2">
      Or <span class="text-fl-blue-600 cursor-pointer" @click="goToManual">Create manually</span>
    </p>

    <FileUploader class="mt-9" :afterUpload="afterUploadHook" />
  </main>
</template>
