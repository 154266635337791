<script setup lang="ts">
import { computed } from 'vue'
interface IProps {
  field: { [key: string]: any }
  label: string
  show?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  field: () => ({}),
  label: '',
  show: false
})

const showError = computed(() => {
  return (
    props.show && (props.field?.$error || props.field?.$invalid || props.field?.$pending?.$response)
  )
})
</script>

<template>
  <p class="text-red-500 mt-1" v-if="showError">
    <span v-if="field.$error">
      <span id="commodity-error" v-for="(error, index) of field.$errors" :key="index">
        <small class="p-error">{{
          label ? error.$message.replace('Value', label) : error.$message
        }}</small>
      </span>
    </span>
    <small v-else-if="field.$invalid || field.$pending.$response" class="p-error">{{
      label ? field.required.$message.replace('Value', label) : field.required.$message
    }}</small>
  </p>
</template>
