import { onBeforeMount } from 'vue'

import { useAppStore } from '@/stores/app'

export const useStorePersist = () => {
  const appStore = useAppStore()

  onBeforeMount(() => {
    const localStore = localStorage.getItem('ai-store')

    if (localStore) {
      appStore.$patch(JSON.parse(localStore))
    }
  })

  appStore.$subscribe((mutation, state) => {
    localStorage.setItem('ai-store', JSON.stringify(state))
    console.log('ai-store', state)
  })
}
