<script setup lang="ts">
import { onMounted, computed } from 'vue'
import BaseModal from './common/BaseModal.vue'
import SvgIcon from './common/SvgIcon.vue'

defineEmits(['close', 'confirm'])

interface IProps {
  closable?: boolean
  isOpen?: boolean
  confirmText?: string
  cancelText?: string
  title?: string
  body?: string
  target?: string
}

const props = withDefaults(defineProps<IProps>(), {
  closable: false,
  cancelText: '',
  confirmText: '',
  isOpen: false,
  title: '',
  body: '',
  target: ''
})

const contentStyle = computed(() => {
  const target = document.getElementById(props.target)
  if (!target) return {}

  const rect = target.getBoundingClientRect()

  const tooltipTop = rect.top + 'px' // Adjust the offset as needed
  const tooltipLeft = rect.left + rect.width - 140 + 'px' // Adjust the offset as needed
  const style = {
    position: 'absolute !important',
    top: `${tooltipTop} !important`,
    left: `${tooltipLeft} !important`
  }
  return style
})

onMounted(() => {})
</script>

<template>
  <Transition>
    <BaseModal
      v-if="isOpen"
      :is-open="isOpen"
      :confirm-text="confirmText"
      :cancel-text="cancelText"
      @close="$emit('close')"
      @confirm="$emit('confirm')"
      :content-style="contentStyle"
      content-class="slide-up"
      :is-prompt="true"
    >
      <template #default>
        <div class="flex gap-4 relative">
          <div class="absolute top-0 -left-[40px] z-[100000000]">
            <SvgIcon name="modalarrow" class="fill-none" />
          </div>
          <div
            class="flex justify-center items-center rounded-full min-w-10 h-10 bg-fl-yellow-500/50"
          >
            <SvgIcon name="mega-phone" class="fill-none stroke-fl-yellow-500" />
          </div>
          <div class="w-full">
            <div class="flex w-full">
              <p class="text-lg mb-2 text-fl-black-500">{{ title }}</p>
              <SvgIcon
                v-if="closable"
                name="close"
                width="20"
                height="20"
                class="fill-gray-400 cursor-pointer ml-auto"
                @click="$emit('close')"
              />
            </div>

            <p class="text-fl-gray-300 text-sm font-normal leading-5">
              {{ body }}
            </p>
          </div>
        </div>
      </template>
    </BaseModal>
  </Transition>
</template>

<style>
@import url('../assets/main.css');

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
