<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
interface IProps {
  label: string
  variant?: 'accent' | 'primary' | 'default'
}

withDefaults(defineProps<IProps>(), {
  label: 'Label',
  variant: 'primary'
})

const variants = {
  accent: 'bg-fl-accent/30 border border-fl-accent text-fl-accent',
  primary: 'bg-fl-primary/30 border border-fl-primary text-fl-primary',
  default: 'bg-white border border-gray-600 text-gray-600'
}
</script>

<template>
  <div class="px-3 py-1 rounded-full font-semibold text-sm" :class="[variants[variant]]">
    {{ label }}
  </div>
</template>
