import PrimeVue from 'primevue/config'

//@ts-ignore
import Lara from '../presets/lara'

/** this is to fix dynamic rendering issue in web components
 * eg: v-if
 * Plugins are not added to app context when
 * dynamically rendered.
 * **/
export const useInstallPrime = (app: any) => {
  if (app?.config?.globalProperties?.$primevue) return
  app.use(PrimeVue, {
    unstyled: true,
    pt: Lara
  })
}
