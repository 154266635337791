<script setup lang="ts">
import RadioButton from 'primevue/radiobutton'

import { useModelWrapper } from '../../composables'

interface IOption {
  label: string
  value: string
}

interface IProps {
  label?: string
  labelClass?: string
  modelValue: string
  list: IOption[]
}

const emits = defineEmits(['update:modelValue'])

const props = withDefaults(defineProps<IProps>(), {
  list: () => [],
  modelValue: '',
  label: ''
})

const localValue = useModelWrapper(props, emits, 'modelValue')
</script>

<template>
  <div class="w-full">
    <p
      v-if="label"
      class="text-sm font-medium leading-5 text-fl-black-500 mb-4"
      :class="labelClass"
    >
      {{ label }}
    </p>
    <div class="flex items-center flex-wrap gap-3 md:gap-10">
      <div v-for="option in list" :key="option.label" class="flex items-center">
        <RadioButton
          v-model="localValue"
          :inputId="option.value"
          name="dynamic"
          :value="option.value"
        />
        <label :for="option.value" class="ml-3 text-fl-black-200 text-sm leading-5">{{
          option.label
        }}</label>
      </div>
    </div>
  </div>
</template>

<style>
@import url('../../assets/main.css');
</style>
