<script setup lang="ts">
import { ref } from 'vue'
import { useUploader } from '../composables'

import { Primary as PrimaryButton } from './common/Button'
import SvgIcon from '@/components/common/SvgIcon.vue'
import ErrorBar from '@/components/common/ErrorBar.vue'
import ProgressBar from './ProgressBar.vue'

interface IProps {
  title?: string
  actionText?: string
  accept?: string
  limit?: number
  sizeLimit?: number
  afterUpload?: (id: string) => Promise<void>
}

const emit = defineEmits(['uploaded'])
const props = withDefaults(defineProps<IProps>(), {
  title: 'Upload Files',
  actionText: 'Browse Files',
  accept:
    'application/pdf, xlsv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx,.xls,.xlsm',
  limit: 2,
  sizeLimit: 1024
})

const fileInputRef = ref<HTMLInputElement>(null as any)

const onUpload = (uploadRes: any) => {
  emit('uploaded', uploadRes)
}

const {
  openFinder,
  setActive,
  setInactive,
  onDrop,
  error,
  fileList,
  onFileSelected,
  uploading,
  uploadProgress
} = useUploader(fileInputRef, props, onUpload)
</script>

<template>
  <div class="w-full max-w-[512px]">
    <ErrorBar v-if="error" :message="error" class="mb-2" />
    <div
      class="w-full rounded-md h-[219px] overflow-hidden bg-dashed-border bg-no-repeat bg-[length:100%_100%]"
      @dragenter.prevent="setActive"
      @dragover.prevent="setActive"
      @dragleave.prevent="setInactive"
      @drop.prevent="onDrop"
    >
      <input
        type="file"
        :accept="accept"
        class="hidden h-0 m-0"
        ref="fileInputRef"
        @change="onFileSelected"
      />
      <div class="w-full h-full flex flex-col items-center justify-center text-sm">
        <SvgIcon name="upload" class="text-none mb-4" />
        <div v-if="!uploading" class="flex flex-col items-center">
          <p class="font-medium text-base leading-5 mb-1">Drag and drop your file here</p>
          <p class="text-xs text-fl-gray-300 leading-5">Supported file types: PDF, XLSX</p>
          <p class="text-sm leading-4 text-fl-gray-400 mt-1">Or</p>
          <PrimaryButton :label="actionText" @click="openFinder" class="mt-4" />
        </div>
        <div v-else class="flex flex-col items-center w-full px-8">
          <p class="text-base font-medium text-fl-gray-400 mb-5">Uploading the file</p>
          <div class="w-full">
            <div v-for="file in fileList" :key="file.name" class="flex items-center gap-1 mb-[2px]">
              <SvgIcon name="document" class="f" />
              <p class="text-xs font-normal text-fl-primary">{{ file.name }}</p>
            </div>
            <ProgressBar variant="accent" :progress="uploadProgress" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.app {
  background: red !important;
}
</style>
