import axios from 'axios'
import { getHeaders } from './methods'

export const initialiseInterceptors = () => {
  axios.defaults.baseURL = import.meta.env.VITE_API_URL

  axios.interceptors.request.use((config: any) => {
    const headers = getHeaders()
    const updatedConfig = { ...config, headers }
    return updatedConfig
  })

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response) {
        const { status } = error.response
        const path = window.location.pathname

        if (status === 401 && path !== '/login') {
          window.location.href = '/login'
        } else {
          throw error
        }
      }

      throw error
    }
  )
}

export default axios
