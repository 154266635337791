<script setup lang="ts">
import { useAppStore } from '@/stores/app'
import SvgIcon from '@/components/common/SvgIcon.vue'
import { Primary as PrimaryButton } from '@/components/common/Button'

const appStore = useAppStore()

const handleRestart = () => {
  appStore.restart()
}

const goToManual = () => {
  const location = window.location.origin
  window.location.href = `${location}/create-deal`
}
</script>

<template>
  <div class="w-full flex flex-col items-center">
    <div class="flex items-center justify-center gap-2">
      <p class="text-2xl font-extrabold">Oops, an error occurred</p>
      <Badge
        label="Beta"
        variant="accent"
        class="!bg-fl-yellow-500 border-0 text-white font-medium"
      />
    </div>
    <div class="w-full flex flex-col items-center justify-center">
      <div class="flex justify-center items-center w-12 h-12 bg-fl-red-200 rounded-full mt-9">
        <SvgIcon name="alert" />
      </div>

      <p class="mt-5 text-sm leading-5 text-fl-gray-300 text-center md:w-[483px]">
        We were unable to fully process the document due to some limitations. Please be aware that
        we are continuously improving the AI auto-extract feature and will resolve this issue soon.
      </p>

      <PrimaryButton label="Create manually" class="mt-9 w-full md:w-[306px]" @click="goToManual" />
      <p class="font-normal text-sm leading-5 mt-2">
        Or
        <span class="text-fl-blue-600 cursor-pointer" @click="handleRestart">Try another file</span>
      </p>
    </div>
  </div>
</template>
