import { ref, computed } from 'vue'
import axios from 'axios'
import { useStorage } from '@vueuse/core'
import { useApi } from '@/api/useApi'

import type { Ref } from 'vue'
import type { CancelTokenSource } from 'axios'

import { DOCUMENTS, SIGNED_URL } from '@/api/endpoints'

export const useUploader = (
  fileInputRef: Ref<any>,
  props: { [key: string]: any },
  onUpload: Function
) => {
  const active = ref(false)
  const uploading = ref(false)
  const totalFiles = ref(0)
  const fileList = ref<File[]>([])
  const currentFile = ref<File | null>(null)
  const currentFileCount = ref(0)
  const uploadProgress = ref(0)
  const error = ref('')
  const cancelToken = ref<CancelTokenSource | null>(null)
  const currentUpload = ref(null)

  const storage = useStorage<any>('auth', {})

  let inActiveTimeout: any = null

  const { mutate } = useApi()
  const sizeLimit = computed(() => props.sizeLimit * 1048576)

  const reset = () => {
    active.value = false
    uploading.value = false
    totalFiles.value = 0
    fileList.value = []
    currentFile.value = null
    currentFileCount.value = 0
    uploadProgress.value = 0
    error.value = ''
    cancelToken.value = null
  }

  const openFinder = () => {
    fileInputRef.value.value = ''
    fileInputRef.value.click()
  }

  const setActive = () => {
    active.value = true
    clearTimeout(inActiveTimeout)
  }

  const setInactive = () => {
    inActiveTimeout = setTimeout(() => {
      active.value = false
    }, 50)
  }

  const formatBytes = computed(() => {
    if (!+props.sizeLimit) return '0 Bytes'
    const decimals = 2

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes: string[] = ['MB', 'GB', 'TB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(props.sizeLimit) / Math.log(k))

    return `${parseFloat((props.sizeLimit / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
  })

  const validateFiles = (eFiles: File[]) => {
    const acceptableFileTypes = props.accept.split(', ')
    const files = eFiles.filter((f: File) => acceptableFileTypes.includes(f.type))
    error.value = ''
    const validation = {
      isPassed: false,
      files
    }

    if (files.length === 0) {
      acceptableFileTypes.length === 0
        ? (error.value = 'No files selected.')
        : (error.value = `Unsupported file type. Please upload a PDF or XLSX file.`)

      return validation
    }

    if (files.length > props.limit) {
      error.value = `You can upload a maximum of ${props.limit} files.`
      return validation
    }

    validation.isPassed = true
    return validation
  }

  const onFileSelected = (e: Event) => {
    const inputElement = e.target as HTMLInputElement
    const { isPassed } = validateFiles([...(inputElement?.files || [])])
    if (!isPassed) {
      return
    }
    fileList.value = [...(inputElement?.files || [])]
    upload()
  }

  const uploadFileToS3 = async (file: File) => {
    currentFile.value = file
    currentFileCount.value++
    uploadProgress.value = 5

    //@ts-ignore
    const filename = file.name?.replaceAll(',', '')

    cancelToken.value = await axios.CancelToken.source()

    const response = await mutate('post', SIGNED_URL, {
      visibility: '',
      content_type: file.type,
      expires: '',
      bucket: ''
    })

    if (!response.success) {
      reset()
      throw new Error(response.error)
    }

    if (!storage.value) {
      throw new Error('User not found')
    }

    const docUploadDetails = {
      file: {
        id: response.data.uuid,
        key: response.data.key,
        name: filename,
        content_type: file.type
      },
      type: 'deal',
      user_id: storage.value?.user?.uuid
    }

    const uploadUrl = response.data.url
    await uploadFile(file, uploadUrl)

    uploadProgress.value = 100
    const res = await mutate('post', DOCUMENTS, docUploadDetails)

    return res
  }

  function uploadFile(file: File, url: string) {
    return new Promise((resolve, reject) => {
      //@ts-ignore
      const filename = file.name?.replaceAll(',', '')

      const formData = new FormData()
      formData.append('file', file)

      const xhr = new XMLHttpRequest()
      xhr.open('PUT', url)

      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 90
          uploadProgress.value =
            uploadProgress.value > percentComplete ? uploadProgress.value : percentComplete
        }
      }

      xhr.onload = function () {
        if (xhr.status === 200) {
          resolve('File uploaded successfully')
        } else {
          reject('Error during upload')
        }
      }

      xhr.onerror = function () {
        reject('Error during upload')
      }

      xhr.setRequestHeader('Accept', 'application/json, text/plain, */*')
      xhr.setRequestHeader('Content-Type', file.type)
      xhr.setRequestHeader('X-Amz-Acl', 'private')
      // xhr.setRequestHeader('Content-Disposition', `attachment; filename=${filename}`)

      xhr.send(file)
    })
  }

  const upload = async () => {
    const files = fileList.value

    for (const file of files) {
      if (file.size > sizeLimit.value && props.sizeLimit) {
        error.value = `File size must be less than ${formatBytes.value}`
        return
      }
    }

    uploading.value = true
    totalFiles.value = files.length

    for (const file of files) {
      uploadProgress.value = 1

      try {
        const res = await uploadFileToS3(file)
        if (!res.success) {
          throw new Error(res.error)
        }
        if (props.afterUpload && typeof props.afterUpload === 'function') {
          await props.afterUpload(res?.data?.data)
        }
        onUpload(res.data)
        currentUpload.value = res.data
      } catch (err) {
        error.value = err as string
      } finally {
        uploading.value = false
        uploadProgress.value = 0
      }
    }
  }

  const onDrop = (e: any) => {
    setInactive()
    const eFiles = [...e.dataTransfer.files]
    const { isPassed, files } = validateFiles(eFiles)

    if (!isPassed) return
    fileList.value = files
    upload()
  }

  return {
    openFinder,
    setInactive,
    setActive,
    onDrop,
    error,
    fileList,
    upload,
    onFileSelected,
    uploading,
    uploadProgress
  }
}
