<script setup lang="ts">
interface IProps {
  progress?: number
  variant?: 'accent' | 'primary' | 'default'
}

withDefaults(defineProps<IProps>(), {
  progress: 0,
  variant: 'default'
})

const variants = {
  accent: {
    wrapper: 'bg-fl-accent/30',
    inner: 'bg-fl-accent'
  },
  primary: {
    wrapper: 'bg-fl-primary/30',
    inner: 'bg-fl-primary'
  },
  default: {
    wrapper: 'bg-gray-200',
    inner: 'bg-gray-700'
  }
}
</script>

<template>
  <div class="w-full h-5 rounded-2xl p-1 bg-fl-light-blue">
    <div
      class="flex items-center h-full rounded-2xl relative transition-all duration-300"
      :class="variants[variant].inner"
      :style="{ width: `${progress}%` }"
    >
      <div class="absolute top-0 right-1 flex items-center text-gray-700 text-sm font-bold"></div>
    </div>
  </div>
</template>
