<script setup lang="ts">
import SvgIcon from './SvgIcon.vue'

import { Secondary, Primary } from './Button'
import { computed } from 'vue'

defineEmits(['close', 'confirm'])

interface IProps {
  isOpen?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | null
  cancelText?: string
  confirmText?: string
  title?: string
  closable?: boolean
  contentStyle?: any
  contentClass?: string
  isPrompt?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  isOpen: false,
  size: null,
  title: '',
  closable: false,
  contentStyle: '',
  contentClass: '',
  isPrompt: false
})

const sizes = {
  sm: 'w-[30%]',
  md: 'w-[50%]',
  lg: 'w-[60%]',
  xl: 'w-[80%]',
  xxl: 'w-[90%]'
}

const size = computed(() => {
  if (!props.size) return props.isPrompt ? 'w-[414px]' : 'w-[512px]'
  return sizes[props.size]
})
</script>

<template>
  <div
    v-if="isOpen"
    class="flex justify-center items-center fixed z-[10000] top-0 bottom-0 left-0 right-0 w-screen min-h-screen overflow-hidden bg-[rgba(0,0,0,0.5)]"
  >
    <div
      class="relative rounded-md bg-white p-4"
      :class="[size, contentClass]"
      :style="contentStyle"
    >
      <slot name="header">
        <div v-if="title || closable" class="flex items-center">
          <p v-if="title" class="text-xl mb-5">{{ title }}</p>
          <SvgIcon
            v-if="closable"
            name="close"
            width="20"
            height="20"
            class="fill-gray-400 cursor-pointer ml-auto"
            @click="$emit('close')"
          />
        </div>
      </slot>
      <slot> </slot>
      <slot name="actions">
        <div v-if="cancelText || confirmText" class="flex justify-end gap-3 mt-4">
          <Secondary
            v-if="cancelText"
            :label="cancelText"
            class="text-sm"
            @click="$emit('close')"
          />
          <Primary
            v-if="confirmText"
            :label="confirmText"
            class="text-sm"
            @click="$emit('confirm')"
          />
        </div>
      </slot>
    </div>
  </div>
</template>
