<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { storeToRefs } from 'pinia'

import { useAppStore } from '@/stores/app'

import Badge from '@/components/common/Badge.vue'
import ConfirmModal from '@/components/ConfirmModal.ce.vue'
import ErrorPage from '@/components/ErrorPage.vue'

const appStore = useAppStore()
const { process } = storeToRefs(appStore)

const isOpen = ref(false)
const cancelProcess = ref(false)
const pollingInterval = ref<any>(null)
const isPolling = ref(false)

const confirmDetails = [
  {
    title: 'Confirm Cancellation',
    body: "Are you sure you want to cancel the auto-extraction of details? Please note that the auto-extracted data won't be saved."
  },
  {
    title: 'Confirm Navigation',
    body: 'Are you sure you want to leave the deal submission incomplete? Please note that extracted data won’t be saved.'
  }
]

const confirm = computed(() => {
  if (cancelProcess.value) return confirmDetails[0]

  return confirmDetails[1]
})

const hasError = computed(() => {
  return process.value?.status?.status === 'failed'
})

const handleCancel = () => {
  cancelProcess.value = true
  isOpen.value = true
}

const handleClose = () => {
  isOpen.value = false
  cancelProcess.value = false
}

const goToManual = () => {
  const location = window.location.origin
  window.location.href = `${location}/create-deal`
}

const handleRestart = () => {
  appStore.restart()
}

const startPolling = () => {
  if (hasError.value) return
  if (appStore.document?.id && !isPolling.value) {
    pollingInterval.value = setInterval(() => {
      if (appStore.process?.status?.status === 'completed') {
        stopPolling()
        return
      }
      appStore.getParsingStatus(appStore.document?.id as string)
      isPolling.value = true
    }, 5000)
  }
}
const stopPolling = () => {
  clearInterval(pollingInterval.value)
  isPolling.value = false
  appStore.abortLatestFetch()
}

const handleConfirm = async () => {
  if (cancelProcess.value) {
    isOpen.value = false
    cancelProcess.value = false

    if (appStore.process?.documentId) {
      const res = await appStore.cancelParsing(appStore.process?.documentId)

      if (res.success) {
        stopPolling()
        appStore.restart()
      }
    }
  }
}

onMounted(() => {
  if (!hasError.value) {
    startPolling()
  }
})

onBeforeUnmount(() => {
  stopPolling()
})

watch(
  () => hasError.value,
  (newValue) => {
    if (newValue) {
      stopPolling()
    }
  }
)
</script>

<template>
  <div
    class="w-full flex flex-col items-center justify-center p-[16px] pt-[50px] h-auto md:h-[500px]"
  >
    <div v-if="!hasError" class="flex flex-col items-center justify-center">
      <div class="flex flex-col md:flex-row items-center gap-2">
        <p class="text-2xl font-extrabold text-center">{{ appStore.process?.status?.title }}</p>
        <Badge
          label="Beta"
          variant="accent"
          class="!bg-fl-yellow-500 border-0 text-white font-medium"
        />
      </div>
      <p class="font-normal text-base leading-6 mt-2 text-fl-black-200 text-center">
        Hang tight! This will just take a moment.
      </p>
      <app-loader class="mt-9" :progress="appStore.process?.status?.percentageComplete" />
      <button @click="handleCancel" class="font-medium text-lg leading-5 mt-9 text-fl-gray-600">
        Cancel
      </button>

      <ConfirmModal
        :title="confirm.title"
        :body="confirm.body"
        @close="handleClose"
        @confirm="handleConfirm"
        :is-open="isOpen"
      />
    </div>
    <ErrorPage v-else />
  </div>
</template>

<style></style>
