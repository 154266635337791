<script setup lang="ts">
import {
  reactive,
  ref,
  computed,
  getCurrentInstance,
  watchEffect,
  watch,
  onBeforeUnmount,
  onBeforeMount
} from 'vue'
import { storeToRefs } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, helpers } from '@vuelidate/validators'

import FieldError from '@/components/FieldErrror.vue'
import { Primary as PrimaryButton } from '@/components/common/Button'

import RadioGroup from '@/components/common/RadioGroup.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'

import { useAppStore } from '@/stores/app'
import { useInstallPrime } from '@/composables'

import Loader from '@/assets/svgs/loader.svg'
import ErrorPage from '@/components/ErrorPage.vue'

const appStore = useAppStore()
const { process, additionalFields } = storeToRefs(appStore)

const submitted = ref(false)
const loading = ref(false)
const fetchingFields = ref(false)
const error = ref('')
const form = reactive({
  loanType: '',
  propertyType: '',
  utilization: ''
})

const rules = {
  loanType: { required },
  utilization: {
    required: helpers.withMessage(
      'This is a required question',
      requiredIf(() => showQuestion({ loanType: ['purchase', 'refinance'] }))
    )
  },
  propertyType: {
    required: helpers.withMessage(
      'This is a required question',
      requiredIf(() => showQuestion({ utilization: 'investment', loanType: 'construction' }))
    )
  }
}

const v$ = useVuelidate(rules, form)

const wrapper = ref<HTMLElement | null>(null)
const loanTypes = ref([
  {
    label: 'Purchase',
    value: 'purchase'
  },
  {
    label: 'Refinance',
    value: 'refinance'
  },
  {
    label: 'Construction',
    value: 'construction'
  }
])

const propertyType = ref([
  {
    label: 'Retail',
    value: 'retail'
  },
  {
    label: 'MultiFamily',
    value: 'multifamily'
  },
  {
    label: 'Office',
    value: 'office'
  },
  {
    label: 'Industrial',
    value: 'industrial'
  },
  {
    label: 'Mixed use',
    value: 'mixed-use'
  },
  {
    label: 'Land',
    value: 'land'
  }
])

const utilizationTypes = computed(() => {
  const list = [
    {
      label: 'Owner-occupied',
      value: 'owner-occupied'
    },
    {
      label: 'Investment',
      value: 'investment'
    },
    {
      label: 'Construction',
      value: 'construction'
    }
  ]
  if (form.loanType === 'refinance') {
    return list.slice(0, 2)
  }

  return list
})

function showQuestion(list?: { [key: string]: string | string[] }) {
  if (!list) return false

  type TKey = keyof typeof form

  const listKeys = Object.keys(list)
  let show = false
  listKeys.forEach((key) => {
    if (Array.isArray(list[key])) {
      const fieldValues = list[key] as string[]
      const contains = fieldValues.some((val) => val === form[key as TKey])
      if (contains) show = contains
    }

    if (form[key as TKey] === list[key]) {
      show = true
    }
  })

  return show
}

const handleContinue = async () => {
  error.value = ''
  submitted.value = true
  const isValid = await v$.value.$validate()

  if (!isValid) {
    return
  }
  loading.value = true
  const payload = {
    loan_type: form.loanType,
    property_type: form.propertyType,
    property_utilization: form.utilization
  }

  if (payload.loan_type === 'construction') {
    //@ts-ignore
    delete payload.property_utilization
  }

  if (payload.loan_type !== 'construction' && payload.property_utilization !== 'investment') {
    //@ts-ignore
    delete payload.property_type
  }

  const res = await appStore.sendAdditionDetails(payload)
  loading.value = false
  if (res.success) {
    const dealId = res?.data?.data?.deal_id

    error.value = ''
    const location = window.location.origin
    window.location.replace(`${location}/create-deal/propertyInfo/${dealId}`)
  } else {
    error.value = res.error
  }
}

const instance = getCurrentInstance()

/** this is to fix dynamic rendering issue in web components
 * eg: v-if
 * Plugins are not added to app context when
 * dynamically rendered.
 * **/
watchEffect(() => {
  if (instance) {
    useInstallPrime(instance.appContext.app)
  }
})

watch(form, (newValue) => {
  const el = document.querySelector('app-component')
  const container = el?.shadowRoot?.getElementById('ai-success-wrapper')
  if (!container) return

  setTimeout(() => {
    container?.scrollTo({ top: container.scrollHeight + 40, behavior: 'smooth' })
  }, 100)
})

watch(
  () => additionalFields.value,
  (newValue) => {
    if (!newValue || typeof newValue !== 'object') return

    Object.keys(newValue).forEach((key) => {
      if (key === 'property_utilization') {
        form.utilization = newValue[key]
        return
      }

      const formattedKey = key.replace(/_./g, (match) =>
        match[1].toUpperCase()
      ) as keyof typeof form

      if (Object.prototype.hasOwnProperty.call(form, formattedKey)) {
        form[formattedKey] = newValue[key]
      }
    })
  }
)

onBeforeMount(async () => {
  fetchingFields.value = true
  await appStore.getAdditionalFields()
  fetchingFields.value = false
})

onBeforeUnmount(() => {
  appStore.restart()
})
</script>

<template>
  <div
    ref="wrapper"
    class="flex flex-col items-center bg-white rounded-[16px] overflow-hidden relative w-full h-full no-scrollbar"
  >
    {{ error }}
    <div v-if="error" class="flex items-center p-[16px] pt-[50px] md:h-[500px]">
      <ErrorPage />
    </div>

    <div
      v-if="!error"
      id="ai-success-wrapper"
      class="flex flex-col items-center flex-1 w-full py-8 px-[16px] md:px-[40px] overflow-x-hidden overflow-y-scroll no-scrollbar"
    >
      <div
        class="flex justify-center items-center w-12 h-12 min-w-12 min-h-12 rounded-full bg-fl-green-100"
      >
        <SvgIcon name="check" />
      </div>

      <p class="text-xl md:text-2xl text-center font-semibold text-fl-black-300 mt-5 mb-4">
        Final Steps to Deal Submission
      </p>
      <p class="text-sm leading-5 text-fl-gray-300 w-full text-center max-w-[910px]">
        We have successfully identified and retrieved {{ process?.status?.dataFields }} data fields.
        Please review them for accuracy and fill in any fields that were not retrieved. Once
        verified, you're ready to publish the deal.
      </p>

      <div class="mt-9 mb-9 h-1 w-[70%] bg-fl-accent"></div>

      <Loader class="mt-12" v-if="fetchingFields" />
      <div v-else class="px-[16px] w-auto md:w-[800px]">
        <div>
          <p class="text-lg font-semibold text-fl-black-300">Before proceeding...</p>
          <p class="text-sm leading-5 text-fl-gray-300">
            Please review the accuracy of the information below or choose the correct option.
          </p>
        </div>

        <div class="flex flex-col gap-6 mt-6 w-full">
          <div id="q1" class="w-full overflow-hidden">
            <RadioGroup
              label="Loan type*"
              :list="loanTypes"
              v-model="v$.loanType.$model"
              labelClass="!font-semibold"
            />
            <FieldError :field="v$.loanType" :show="submitted" label="Loan type" />
          </div>

          <div id="q2" v-if="showQuestion({ loanType: ['purchase', 'refinance'] })">
            <RadioGroup
              label="How will the property be utilized?*"
              labelClass="!font-semibold"
              :list="utilizationTypes"
              v-model="v$.utilization.$model"
            />
            <FieldError :field="v$.utilization" :show="submitted" label="Utilization" />
          </div>

          <div
            id="q3"
            v-if="
              form.loanType && showQuestion({ utilization: 'investment', loanType: 'construction' })
            "
          >
            <RadioGroup
              label="What's the property type?*"
              :list="propertyType"
              v-model="v$.propertyType.$model"
              labelClass="!font-semibold"
            />
            <FieldError :field="v$.propertyType" :show="submitted" label="Property type" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!fetchingFields && !error"
      class="flex justify-end w-full bg-fl-light-gray py-3 px-6 sticky bottom-0 mt-auto"
    >
      <PrimaryButton label="Continue" @click="handleContinue" :loading="loading" />
    </div>
  </div>
</template>

<style scoped></style>
