import { defineStore, createPinia, setActivePinia } from 'pinia'
import { useApi } from '@/api/useApi'

import { DOCUMENT_DETAILS, CANCEL_PARSE, PARSING_STATUS } from '@/api/endpoints'

setActivePinia(createPinia())

interface IProcess {
  documentId: string
  steps: string[]
  status: {
    currentStep: string
    title: string
    estimatedTime: number
    status: 'in-progress' | 'completed' | 'failed'
    error: string | null
    dataFields: number
    percentageComplete: number
  }
}

interface IState {
  steps: { id: number; label: string }[]
  document: {
    id: string
    user_id: number
    type: string
    file_type: string
    name: string
    url: string
    file_size: string
    page_count: number | null
  } | null
  process: IProcess | null
  additionalDetailsSent: boolean
  additionalFields: any
}

const { mutate, mutationAbortController, fetchAbortController } = useApi()

export const useAppStore = defineStore('ai-store', {
  state: () =>
    ({
      steps: [
        { id: 1, label: 'Preparing Your File' },
        { id: 2, label: 'Extracting Text' },
        { id: 3, label: 'Identifying Important Data' },
        { id: 4, label: 'Ensuring Accuracy' },
        { id: 5, label: 'Ready to Go!' }
      ],
      document: null,
      process: null,
      additionalDetailsSent: false,
      additionalFields: null
    }) as IState,
  getters: {},
  actions: {
    async sendAdditionDetails(payload: any) {
      if (!this.process?.documentId) {
        return {
          success: false,
          error: 'No uploaded document found'
        }
      }
      const res = await mutate('put', DOCUMENT_DETAILS(this.process?.documentId), payload)
      if (res.success) {
        this.additionalDetailsSent = true
      }
      return res
    },
    async getParsingStatus(id: string) {
      const res = await mutate('get', PARSING_STATUS(id))
      this.process = res.data?.data
      console.log('parsing status', res)
      return res
    },

    async restart() {
      this.process = null
      this.document = null
      this.additionalDetailsSent = false
    },

    async cancelParsing(id: string) {
      const res = await mutate('put', CANCEL_PARSE(id))
      if (res.success) {
        this.restart()
      }
      return res
    },

    async getAdditionalFields() {
      if (!this.process?.documentId) {
        return {
          success: false,
          error: 'No uploaded document found'
        }
      }
      const res = await mutate('get', DOCUMENT_DETAILS(this.process?.documentId))
      if (res.success) {
        this.additionalFields = res.data?.data
      }
      return res
    },
    async abortLatestFetch() {
      if (fetchAbortController) {
        fetchAbortController.abort()
      }
    },

    async abortLatestMutation() {
      if (mutationAbortController) {
        mutationAbortController.abort()
      }
    }
  }
})
